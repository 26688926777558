import {
  Component,
  FC,
  HTMLProps,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styles from "./ResultScreen.module.scss";
import classNames from "classnames";
import gsap from "gsap";
import { useDispatch, useSelector } from "../../store/hooks";
import FieldImage from "../../components/Field/FieldImg";
import { Button } from "@mui/material";
import { setAppState } from "../../store/reducers/globalSlice";
import Pagination from "../../components/Pagination";
import {
  blobToBase64,
  documentToFieldArray,
  imgToBlob,
  setImageParams,
  splitPassportSerialNumber,
} from "../../utils/functions";
import useNotify from "../../utils/hooks/useNotify";
import { useTranslation } from "react-i18next";
import GlobalWarningCard from "../../components/GlobalWarningCard";
import Field from "../../components/Field";
import ReactJson from "react-json-view";
import { useSearchParams } from "react-router-dom";
import { hasNoTextDetection } from "../../utils/constants";
import BooleanField from "../../components/BooleanField";
import GlobalWarnings from "./GlobalWarnings";

export type Props = { addClasses?: string[] } & HTMLProps<HTMLDivElement>;

const ResultScreen: FC<Props> = (props) => {
  const { addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );

  const mrzFieldsClass = classNames([styles["fields"], styles["mrz-fields"]]);
  const stampFieldClass = classNames([
    styles["stamp-field"],
    styles["field"],
    "field",
  ]);

  const { documents } = useSelector((state) => state.result);
  const { fileSrc, fileExtension } = useSelector((state) => state.file);
  const fieldsListRef = useRef<HTMLDivElement>(null);
  const mrzFieldsListRef = useRef<HTMLDivElement>(null);
  const debugFieldsListRef = useRef<HTMLDivElement>(null);

  const [imgSize, setImgSize] = useState({ w: 0, h: 0 });
  const [docType, setDocType] = useState("");
  const [pagination, setPagination] = useState(0);
  const [selectedField, setSelectedField] = useState("");
  const { notify } = useNotify();
  const dispatch = useDispatch();
  const tl = useRef<gsap.core.Timeline>();
  const mm = gsap.matchMedia();
  const currentDocument = documents ? documents[pagination] : undefined;
  const { t, i18n } = useTranslation();
  const [documentConfidence, setDocumentConfidence] = useState(0);
  const [imgBase64, setImgBase64] = useState("");
  const [viewbox, setViewbox] = useState("0 0 100 100");
  const [searchParams] = useSearchParams();

  const debugModeIsOn = searchParams.get("debug") == "true";

  const showMrzSection =
    (docType === "RUSSIAN_PASSPORT_P2_P3" ||
      docType === "RUSSIAN_PASSPORT_P3") &&
    currentDocument?.mrz_fields &&
    Object.entries(currentDocument.mrz_fields).length;

  const bboxWeight = Math.max(imgSize.w, imgSize.h) * 0.003;
  const docStamps = currentDocument?.stamps
    ?.filter(({ score }) => score >= 0.45)
    .sort((a, b) => b.score - a.score);

  const mrzParsingErrors =
    currentDocument?.mrz_first_line_not_parsed_prefix ||
    currentDocument?.mrz_second_line_not_parsed_prefix ||
    currentDocument?.mrz_second_line_not_parsed_suffix;

  const orderedFields = currentDocument
    ? documentToFieldArray(currentDocument)
    : [];

  const nextDocument = () => {
    setPagination((prev) => {
      if (prev === documents!.length - 1) {
        return 0;
      }
      return ++prev;
    });
  };

  const prevDocument = () => {
    setPagination((prev) => {
      if (prev === 0) {
        return documents!.length - 1;
      }
      return --prev;
    });
  };

  const toInitialScreen = () => {
    dispatch(setAppState("initial"));
  };

  useLayoutEffect(() => {
    if (!documents || !documents.length) {
      return notify("error", "nothingDetected");
    }
    const mostConfident = Object.entries(documents[0].types).sort(
      ([_, c1], [n1, c2]) => c2 - c1
    )[0];
    const [_, confidence] = mostConfident;
    if (confidence < window.DOCUMENT_TYPE_MIN_CONFIDENCE) {
      gsap.set(fieldsListRef.current, { width: 0 });
      gsap.set(mrzFieldsListRef.current, { width: 0 });
      gsap.set(debugFieldsListRef.current, { width: 0 });
      return;
    }
    mm.add("(min-width: 800px)", () => {
      tl.current = gsap.timeline();
      tl.current.from(fieldsListRef.current, { width: 0 });
      tl.current.from(mrzFieldsListRef.current, { width: 0 }, "<");
      tl.current.from(debugFieldsListRef.current, { width: 0 }, "<");
    });
  }, []);

  useLayoutEffect(() => {
    if (!currentDocument) return;
    const ctxFields = gsap.context(() => {
      gsap.fromTo(".field", { x: "100%" }, { x: 0, stagger: 0.1 });
    }, fieldsListRef);
    const ctxMrzFields = gsap.context(() => {
      gsap.fromTo(".field", { x: "100%" }, { x: 0, stagger: 0.1 });
    }, mrzFieldsListRef);
    const ctxDebugFields = gsap.context(() => {
      gsap.fromTo(".field", { x: "100%" }, { x: 0, stagger: 0.1 });
    }, debugFieldsListRef);

    const mostConfident = Object.entries(currentDocument.types).sort(
      ([_, c1], [n1, c2]) => c2 - c1
    )[0];
    const [type, confidence] = mostConfident;
    setDocType(type);
    setDocumentConfidence(confidence);
    if (currentDocument.normalized_view) {
      setImgBase64(`data:image/*;base64,${currentDocument.normalized_view}`);
      setImageParams(
        `data:image/*;base64,${currentDocument.normalized_view}`,
        (img) => {
          setImgSize({
            w: img.width < 3000 ? img.width : 3000,
            h: img.height,
          });
          setViewbox(`0 0 ${img.width} ${img.height}`);
        },
        () => {
          notify("error", "photo.error");
          dispatch(setAppState("initial"));
        }
      );
    } else {
      const { x, y, w, h } = currentDocument.bbox;
      setViewbox(`${x} ${y} ${w} ${h}`);
      imgToBlob(fileSrc).then((blob: Blob) => {
        blobToBase64(blob).then((base64: any) => {
          setImgBase64(base64 as string);
          setImageParams(
            base64 as string,
            (img) => {
              setImgSize({
                w: img.width < 3000 ? img.width : 3000,
                h: img.height,
              });
            },
            () => {
              notify("error", "photo.error");
              dispatch(setAppState("initial"));
            }
          );
        });
      });
    }
    if (confidence < window.DOCUMENT_TYPE_MIN_CONFIDENCE) {
      notify("warning", "unknownDocType");
    } else {
      notify("success", type);
    }
    return () => {
      ctxFields.kill();
      ctxMrzFields.kill();
      ctxDebugFields.kill();
    };
  }, [currentDocument]);

  if (!currentDocument) return null;

  return (
    <div className={className} {...props}>
      <div className={styles["img-container"]}>
        {fileExtension === "application/pdf" &&
        (hasNoTextDetection as any)[docType] ? (
          <p className={styles["img-placeholder"]}>
            {t("titles.unable_to_display")}
          </p>
        ) : (
          <svg className={styles["img"]} viewBox={viewbox}>
            <image href={imgBase64} width={imgSize.w} height={imgSize.h} />
            {documentConfidence > window.DOCUMENT_TYPE_MIN_CONFIDENCE && (
              <>
                {Object.entries(currentDocument.fields || {}).map(
                  ([name, f]) => {
                    return (
                      <rect
                        className="bbox"
                        x={f.position.x}
                        y={f.position.y}
                        width={f.position.w}
                        height={f.position.h}
                        onMouseEnter={() => {
                          setSelectedField(name);
                        }}
                        stroke={name == selectedField ? "#263b47" : "#036ab5"}
                        fill="transparent"
                        strokeWidth={
                          name === selectedField ? bboxWeight * 2 : bboxWeight
                        }
                      />
                    );
                  }
                )}
              </>
            )}
            {(() => {
              if (!docStamps?.length) return null;
              const { bbox } = docStamps[0];
              const { x, y, w, h } = bbox;
              const selected = selectedField == "stamp";
              return (
                <rect
                  className="bbox"
                  x={x}
                  y={y}
                  width={w}
                  height={h}
                  onMouseEnter={() => {
                    setSelectedField("stamp");
                  }}
                  stroke={selected ? "#263b47" : "#036ab5"}
                  fill="transparent"
                  strokeWidth={selected ? bboxWeight * 2 : bboxWeight}
                />
              );
            })()}
          </svg>
        )}

        {documents!.length > 1 && (
          <Pagination
            next={nextDocument}
            prev={prevDocument}
            sign={`${pagination + 1}/${documents!.length}`}
          />
        )}
        <Button onClick={toInitialScreen}>{t("button.try")}</Button>
      </div>
      <div className={styles["fields"]} ref={fieldsListRef}>
        <>
          {(hasNoTextDetection as any)[docType] ? (
            <p style={{ width: "100%" }}>{t("titles.text_unsupported")}</p>
          ) : (
            <>
              {orderedFields.map((f) => {
                let birthdate_warning: string[] = [];
                if (f.name === "birth_date") {
                  const year = +f.text.split(".")[2];
                  if (!isNaN(year)) {
                    const currentYear = new Date().getFullYear();
                    if (currentYear - year >= 120) {
                      birthdate_warning = [t("warnings.suspicious_age")];
                    }
                  }
                }
                const { name } = f;
                const pageHasSerialNumber =
                  docType === "RUSSIAN_PASSPORT_P2_P3" ||
                  docType === "RUSSIAN_PASSPORT_P2" ||
                  docType === "RUSSIAN_PASSPORT_P3";
                let transformedText =
                  name === "serial_number" && pageHasSerialNumber
                    ? splitPassportSerialNumber(f.text)
                    : f.text;

                const translatedWarnings = f.warnings?.map((w) => {
                  if (w == "ISSUER_CODE_INCONSISTENCY")
                    return t(`warnings.${w}`, {
                      issuer:
                        f.issuer_code_inconsistency_details
                          ?.most_similar_issuer,
                    });
                  return i18n.exists(`warnings.${w}.${name}`)
                    ? t(`warnings.${w}.${name}`)
                    : t(`warnings.${w}`);
                });

                const { x, y, w, h } = f.position;

                const fieldImgPosition =
                  name === "code"
                    ? `${y - w} ${-x} ${h} ${w}` // axises change since image is rotated
                    : `${x} ${y} ${w} ${h}`;

                const Image = !transformedText ? (
                  <FieldImage
                    viewBox={fieldImgPosition}
                    imageProps={{
                      width: imgSize.w,
                      height: imgSize.h,
                      src: imgBase64,
                      rotate: name === "code",
                    }}
                  />
                ) : undefined;

                const handleCopy = Image
                  ? undefined
                  : () => {
                      notify("info", "copied");
                      navigator.clipboard.writeText(transformedText);
                    };
                const warnings = translatedWarnings
                  ? [...translatedWarnings, ...birthdate_warning]
                  : [...birthdate_warning];
                const fieldNameTranslation = i18n.exists(
                  `fields.custom_names.${docType}.${name}`
                )
                  ? t(`fields.custom_names.${docType}.${name}`)
                  : t(`fields.${name}`);
                return (
                  <Field
                    key={name}
                    name={fieldNameTranslation}
                    textValue={transformedText}
                    Image={Image}
                    selected={selectedField == name}
                    onCopy={handleCopy}
                    addClasses={["field", styles["field"]]}
                    onMouseOver={() => {
                      setSelectedField(name);
                    }}
                    warnings={warnings}
                    vstack={name == "mrz"}
                  />
                );
              })}
            </>
          )}
        </>
        <div className={stampFieldClass}>
          {(() => {
            const docMustHaveStamp =
              docType === "RUSSIAN_PASSPORT_P2_P3" ||
              docType === "RUSSIAN_PASSPORT_P2";
            if (!docMustHaveStamp) return null;
            let Image = null;
            if (docStamps?.length) {
              const stamp = docStamps[0];
              const { bbox } = stamp;
              const { x, y, w, h } = bbox;
              const fieldImgPosition = `${x} ${y} ${w} ${h}`;
              Image = (
                <FieldImage
                  viewBox={fieldImgPosition}
                  imageProps={{
                    width: imgSize.w,
                    height: imgSize.h,
                    src: imgBase64,
                  }}
                  style={{ height: 60 }}
                />
              );
            }
            const warnings = Image ? undefined : [t("warnings.no_stamp")];
            return (
              <BooleanField
                name={t("fields.has_stamp")}
                isPositive={Image != null}
                isRed={Image == null}
                onMouseOver={() => {
                  setSelectedField("stamp");
                }}
                selected={selectedField == "stamp"}
                warnings={warnings}
              >
                {Image}
              </BooleanField>
            );
          })()}
        </div>
        {currentDocument.is_grayscale !== undefined && (
          <BooleanField
            addClasses={["field", styles["field"]]}
            name={t("fields.is_grayscale")}
            isPositive={currentDocument.is_grayscale}
          />
        )}
        {currentDocument.is_handwritten !== undefined &&
          (() => {
            const warning = currentDocument.global_warnings?.includes(
              "HANDWRITTEN_TEXT_NOT_EXPECTED"
            )
              ? t("warnings.serial_number_handwritten_inconsistency")
              : undefined;
            return (
              <BooleanField
                addClasses={["field", styles["field"]]}
                name={t("fields.is_handwritten")}
                isPositive={currentDocument.is_handwritten}
                isRed={!!warning}
                warnings={warning ? [warning] : undefined}
              />
            );
          })()}
        {currentDocument.rf_sign !== undefined &&
          (() => {
            const { global_warnings } = currentDocument;
            const rfSignWarnings = global_warnings
              ?.filter((w) => w.includes("RF_SIGN_"))
              .map((w) => t(`warnings.${w}`));
            const displayWarnings = !!rfSignWarnings && !!rfSignWarnings.length;
            return (
              <BooleanField
                addClasses={["field", styles["field"]]}
                name={t("fields.rf_sign")}
                isPositive={currentDocument.rf_sign}
                isRed={displayWarnings}
                warnings={displayWarnings ? rfSignWarnings : undefined}
              />
            );
          })()}
      </div>
      {showMrzSection ? (
        <>
          <h2 className={styles["title"]}>{t("titles.mrz_fields")}</h2>
          <div className={mrzFieldsClass} ref={mrzFieldsListRef}>
            {Object.entries(currentDocument.mrz_fields!).map(([name, f]) => {
              const translatedWarnings = f.warnings.map((w) => {
                return i18n.exists(`warnings.${w}.${name}`)
                  ? t(`warnings.${w}.${name}`)
                  : t(`warnings.${w}`);
              });
              return (
                <Field
                  key={`mrz_${name}`}
                  name={t(`fields.${name}`)}
                  textValue={f.text}
                  onMouseOver={() => {
                    setSelectedField("mrz");
                  }}
                  onCopy={() => {
                    notify("info", "copied");
                    navigator.clipboard.writeText(f.text);
                  }}
                  addClasses={["field", styles["field"]]}
                  warnings={translatedWarnings}
                  fillOnHover
                />
              );
            })}
            {currentDocument.missing_mrz_fields_warnings &&
              Object.entries(currentDocument.missing_mrz_fields_warnings).map(
                ([name]) => {
                  return (
                    <Field
                      key={`missing_mrz_${name}`}
                      name={t(`fields.${name}`)}
                      textValue={""}
                      addClasses={["field", styles["field"]]}
                      warnings={[t("warnings.REQUIRED_FIELD_IS_MISSING")]}
                      fillOnHover
                    />
                  );
                }
              )}
          </div>
        </>
      ) : null}
      <GlobalWarnings document={currentDocument} />
      {mrzParsingErrors ? (
        <div className={styles["mrz-warnings"]}>
          <h2>{t("titles.mrz_warnings")}</h2>
          {currentDocument.mrz_first_line_not_parsed_prefix && (
            <GlobalWarningCard
              title={t("warnings.mrz_first_line_not_parsed_prefix")}
              warnings={[currentDocument.mrz_first_line_not_parsed_prefix]}
            />
          )}
          {currentDocument.mrz_second_line_not_parsed_prefix && (
            <GlobalWarningCard
              title={t("warnings.mrz_second_line_not_parsed_prefix")}
              warnings={[currentDocument.mrz_second_line_not_parsed_prefix]}
            />
          )}
          {currentDocument.mrz_second_line_not_parsed_suffix && (
            <GlobalWarningCard
              title={t("warnings.mrz_second_line_not_parsed_suffix")}
              warnings={[currentDocument.mrz_second_line_not_parsed_suffix]}
            />
          )}
        </div>
      ) : null}

      {debugModeIsOn && (
        <div className={styles["debug-list"]} ref={debugFieldsListRef}>
          <h2 className={styles["title"]}>Debug</h2>
          <div className={styles["types"]}>
            {Object.entries(currentDocument.debug_info!.localizer_types).map(
              ([name, val]) => (
                <span>
                  {name}: {val}
                </span>
              )
            )}
          </div>
          {(() => {
            if (!currentDocument.debug_info?.raw_mrz) return null;
            const { mrz_1, mrz_2 } = currentDocument.debug_info.raw_mrz;
            return (
              <>
                {mrz_1 && (
                  <div className={styles["mrz-score"]}>
                    <span>mrz_1</span>
                    <span>score: {mrz_1.score}</span>
                    <span>content: {mrz_1.text}</span>
                    <FieldImage
                      viewBox={`${mrz_1.x} ${mrz_1.y + imgSize.h / 2} ${
                        mrz_1.w
                      } ${mrz_1.h}`}
                      imageProps={{
                        width: imgSize.w,
                        height: imgSize.h,
                        src: `data:image/*;base64,${currentDocument.normalized_view}`,
                      }}
                    />
                  </div>
                )}
                {mrz_2 && (
                  <div className={styles["mrz-score"]}>
                    <span>mrz_2</span>
                    <span>score: {mrz_2.score}</span>
                    <span>content: {mrz_2.text}</span>
                    <FieldImage
                      viewBox={`${mrz_2.x} ${mrz_2.y + imgSize.h / 2} ${
                        mrz_2.w
                      } ${mrz_2.h}`}
                      imageProps={{
                        width: imgSize.w,
                        height: imgSize.h,
                        src: `data:image/*;base64,${currentDocument.normalized_view}`,
                      }}
                    />
                  </div>
                )}
              </>
            );
          })()}
        </div>
      )}
      {documents && debugModeIsOn && (
        <ReactJson
          src={currentDocument}
          name="current_document"
          shouldCollapse={() => true}
        />
      )}
    </div>
  );
};

export default ResultScreen;
